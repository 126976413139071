import './style.scss'

import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import IconArrowDown from '@/assets/img/speech2text/Caret_Down_MD.svg'

const FAQ = () => {
  const { t } = useTranslation(['common'])
  const [toggleContent, setToggleContent] = useState<any>({
    0: true,
    1: true,
    2: true,
  })

  const listItems = [
    {
      title: t('faq_title_1'),
      description: t('faq_description_1'),
    },
    {
      title: t('faq_title_2'),
      description: t('faq_description_2'),
    },
    {
      title: t('faq_title_3'),
      description: t('faq_description_3'),
    },
  ]

  const renderListItems = listItems.map((item, index) => {
    return (
      <div className="wrapper-faq-content" key={index}>
        <div
          className={classNames('faq-content', {
            hide: !toggleContent[index],
          })}
        >
          <div className="faq-top">
            <p className="faq-index">0{index + 1}</p>
            <p className="faq-title">{item.title}</p>
            <img
              src={IconArrowDown}
              alt="Arrow down"
              className="btn-icon-arrow-down"
              onClick={() =>
                setToggleContent({
                  ...toggleContent,
                  [index]: !toggleContent[index],
                })
              }
            />
          </div>
          <p className="faq-description">{item.description}</p>
        </div>
      </div>
    )
  })

  return (
    <section className="faq" id="faq">
      <div className="main-container custom-container">
        <h2 className="title">
          {t('Frequently Asked Questions about Speech-to-Text Conversion')}
        </h2>

        <div className="list-content-items">{renderListItems}</div>
      </div>
    </section>
  )
}

export default FAQ
