import './style.scss'

import { useTranslation } from 'react-i18next'

import ImageMic from '@/assets/img/mic.png'
import ImageSound from '@/assets/img/sound.png'
import IconUploadFile from '@/assets/svg/upload-file.svg'
import Button from '@/components/Common/Item/Button'
import PageTitle from '@/components/Common/Item/PageTitle'

const SpeechToText: React.FC = () => {
  const { t } = useTranslation(['common'])

  return (
    <div className="speech-to-text">
      <PageTitle title={t('Convert speech to text')} />

      <div className="page-body">
        <div className="wrapper-input">
          <h3 className="title">{t('Record from browser')}</h3>
          <p className="description">
            {t('Click the microphone icon and speak')}
          </p>

          <div className="record-images">
            <img src={ImageSound} className="img-sound" alt="sound" />
            <img src={ImageMic} className="img-mic" alt="mic" />
          </div>

          <p className="time-record">00:00:00</p>

          <div className="box-actions">
            <Button danger disabled radius className="w-[150px] h-[40px]">
              {t('Stop')}
            </Button>

            <hr />

            <p className="note-upload">
              {t('Or upload an existing audio file')}
            </p>

            <Button radius className="w-[100%] h-[40px] btn-upload">
              <img
                src={IconUploadFile}
                alt="upload file"
                width={24}
                height={24}
              />
              <span>{t('Upload your file')}</span>
            </Button>
          </div>
        </div>
        <div className="wrapper-result">
          <p className="result-label">{t('Result')}</p>
          <div className="result-box"></div>
        </div>
      </div>
    </div>
  )
}

export default SpeechToText
