import './HeaderStyle.scss'

import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Flag_EN from '@/assets/img/fl_en.png'
import Flag_JP from '@/assets/img/fl_jp.png'
import Flag_KR from '@/assets/img/fl_kr.png'
import Flag_VN from '@/assets/img/fl_vn.png'
import LogoColor from '@/assets/img/LOGO_color.png'
// import LogoWhite from '@/assets/img/LOGO_white.png'
import useScrollToSection from '@/components/CustomHook/useScrollToSection'
import { useToggle } from '@/components/CustomHook/useToggle'
import { IconList, IconRound } from '@/components/Icons'

const Header: React.FC = () => {
  const { i18n, t } = useTranslation(['common'])

  const [togglenavMobile, handleToggleNavMobile] = useToggle(false)
  const [toggleProductMb, handleToggleProductMb] = useToggle(false)
  // const [isTop, setIsTop] = useState(true)

  const { scrollToSection } = useScrollToSection()

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll)

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll)
  //   }
  // }, [])

  // const handleScroll = () => {
  //   if (window.scrollY <= 80) {
  //     setIsTop(true)
  //   } else setIsTop(false)
  // }

  const getImageLanguageFlag = () => {
    switch (i18n.language) {
      case 'en':
        return Flag_EN
      case 'jp':
        return Flag_JP
      case 'vn':
        return Flag_VN
      case 'kr':
        return Flag_KR
      default:
        return Flag_EN
    }
  }

  return (
    <header
      className={classNames('header sub-layout', {
        // transparent: isTop && !isSubLayout,
        // 'sub-layout': isSubLayout,
      })}
    >
      <nav className="navbar">
        <div className="logo">
          <Link to={'/'}>
            <img
              // src={isTop && !isSubLayout ? LogoWhite : LogoColor}
              src={LogoColor}
              height={42}
            />
          </Link>
        </div>
        <ul className="nav-list">
          <li>
            <Link to="/">{t('Home')}</Link>
          </li>
          <li className="header-dropdown">
            <Link to={''} className="show">
              {t('AI Products')} <IconRound width={16} height={16} />
            </Link>

            <div className={'header-dropdown-content'}>
              <Link to="/speech-to-text">{t('Speech to Text')}</Link>
              <Link to="/ocr">{t('OCR')}</Link>
              <Link to="/face-recognition">{t('Face Recognition')}</Link>
              <Link to="/dify-ai">{t('DifyAI')}</Link>
              <Link to="/libre-chat">{t('LibreChat')}</Link>
              <Link to="/">{t('Llama3')}</Link>
              <Link to="/">{t('Stable Diffusion')}</Link>
            </div>
          </li>
          {/* <li className="header-dropdown">
            <Link to={''} className="show">
              {t('Blockchain Products')} <IconRound width={16} height={16} />
            </Link>
            <div className="header-dropdown-content">
              <Link to="/products/nft-marketplace">{t('NFT Marketplace')}</Link>
              <Link to="/products/crypto-exchange">
                {t('Crypto Exchange (CEX)')}
              </Link>
              <Link to="/products/decentralized-exchange">
                {t('Decentralized Exchange (DEX)')}
              </Link>
            </div>
          </li>
          <li className="header-dropdown">
            <Link to={''} className="show">
              {t('ERP Products')} <IconRound width={16} height={16} />
            </Link>
            <div className="header-dropdown-content">
              <Link to="/bemo-cloud">{t('Bemo Cloud')}</Link>
            </div>
          </li> */}
          <li>
            <Link onClick={() => scrollToSection('contact')} to={''}>
              {t('Contact')}
            </Link>
          </li>
          <li>
            <a href={'/blog'}>{t('Blog')}</a>
          </li>

          <li className="language-icons header-dropdown">
            <Link to={''} className="show current-language">
              <img src={getImageLanguageFlag()} height={24} />{' '}
              <IconRound width={16} height={16} />
            </Link>

            <div className={'header-dropdown-content'}>
              <Link
                className="flag-item"
                to=""
                onClick={() => i18n.changeLanguage('en')}
              >
                <img src={Flag_EN} height={24} />
                {t('English')}
              </Link>
              <Link
                className="flag-item"
                to=""
                onClick={() => i18n.changeLanguage('jp')}
              >
                <img src={Flag_JP} height={24} />
                {t('Japanese')}
              </Link>
              <Link
                className="flag-item"
                to=""
                onClick={() => i18n.changeLanguage('vn')}
              >
                <img src={Flag_VN} height={24} />
                {t('Vietnamese')}
              </Link>
              <Link
                className="flag-item"
                to=""
                onClick={() => i18n.changeLanguage('kr')}
              >
                <img src={Flag_KR} height={24} />
                {t('Korean')}
              </Link>
            </div>
          </li>
        </ul>

        <button className="header-menu" onClick={handleToggleNavMobile}>
          <IconList width={24.8} height={18.92} />
        </button>
      </nav>
      <div className={'nav-mobile ' + (togglenavMobile ? 'active' : '')}>
        <ul className={'nav-list ' + (toggleProductMb && 'active')}>
          <li className="header-dropdown" onClick={handleToggleProductMb}>
            <span>
              <Link to={''}>{t('Product')} </Link>
              <IconRound width={16} height={16} />
            </span>
          </li>
          <li>
            <Link onClick={() => scrollToSection('about-us')} to={''}>
              {t('Company')}
            </Link>
          </li>
          <li>
            <a href={'/blog'}>{t('Blog')}</a>
          </li>
          <li>
            <Link onClick={() => scrollToSection('contact')} to={''}>
              {t('Contact')}
            </Link>
          </li>
          <li className="language-icons">
            <Languages />
          </li>
        </ul>
      </div>
    </header>
  )
}

const Languages: React.FC = () => {
  const { i18n } = useTranslation(['common'])

  return (
    <>
      <img
        src={Flag_JP}
        height={24}
        onClick={() => i18n.changeLanguage('jp')}
      />
      <img
        src={Flag_EN}
        height={24}
        onClick={() => i18n.changeLanguage('en')}
      />
      <img
        src={Flag_VN}
        height={24}
        onClick={() => i18n.changeLanguage('vn')}
      />
      <img
        src={Flag_KR}
        height={24}
        onClick={() => i18n.changeLanguage('kr')}
      />
    </>
  )
}

export default Header
