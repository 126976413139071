import './SubLayout.scss'

import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import useScrollDefault from '@/components/CustomHook/useScrollDefault'

import Header from './Header'
import SidebarAIProducts from './SidebarAIProducts'

const Layout: React.FC = () => {
  const location = useLocation()
  const path = location.pathname.substring(1)
  useScrollDefault(path)
  return (
    <div className="wrapper-sub-layout">
      <Header />

      <SidebarAIProducts />
      <div className="wrapper-content">
        <div className="page">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default Layout
