import './style.scss'

import { Upload, UploadProps } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

import ImageUploadDragger from '@/assets/img/UploadDragger.png'
import Button from '@/components/Common/Item/Button'

const { Dragger } = Upload

interface CustomUploadProps extends UploadProps {
  className?: string;
  icon?: string;
}

const UploadDragger: React.FC<CustomUploadProps> = ({
  className,
  icon,
  ...props
}) => {
  const { t } = useTranslation(['common'])

  return (
    <Dragger
      {...props}
      beforeUpload={() => false}
      className={classNames('dragger-upload-default', className)}
    >
      <div className="icon-upload">
        <img src={icon || ImageUploadDragger} alt="Upload dragger" />
      </div>
      <p className="ant-upload-text">
        {t('Drag and drop files here to upload')}
      </p>
      <p className="ant-upload-hint">
        {t('Upload unlimited files, 50MB total limit.')}
      </p>
      <p className="ant-upload-hint or">{t('Or')}</p>
      <Button radius className="w-[125px] h-[36px]">
        {t('Select file')}
      </Button>
    </Dragger>
  )
}

export default UploadDragger
