import './style.scss'

import { useTranslation } from 'react-i18next'

import ImageAIBot from '@/assets/img/Ai_Bot.png'
import IconChat from '@/assets/svg/Chat_Circle_Dots.svg'
import Button from '@/components/Common/Item/Button'
import PageTitle from '@/components/Common/Item/PageTitle'

const AIChats: React.FC = () => {
  const { t } = useTranslation(['common'])

  return (
    <div className="ai-chat">
      <PageTitle title={t('Ai Chat')} />

      <div className="page-body">
        <div className="wrapper-init-chat">
          <img src={ImageAIBot} alt="AI Bot" />

          <Button className="btn-chat-now min-w-[125px] h-[36px]" radius>
            <img src={IconChat} alt="Chat now" />

            {t('Chat now')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AIChats
