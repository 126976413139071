import './style.scss'

import classNames from 'classnames'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@/components/Common/Item/Button'
import Input from '@/components/Common/Item/Input'
import TextArea from '@/components/Common/Item/TextArea'
import ErrorValidateText from '@/components/Common/Pages/Contact/ErrorValidateText'
import customAxios from '@/libs/axios'

import { contactFormSchema } from './ContactFormSchema'

const Contact = () => {
  const { t } = useTranslation(['common'])

  const [loadingBtn, setLoadingBtn] = useState(false)
  // const [resultContact, setResultContact] = useState(false)
  const { REACT_APP_FORMSPREE_ENDPOINT } = process.env
  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      companyName: '',
      phone: '',
      messenger: '',
      recaptcha: '',
    },
    validationSchema: contactFormSchema,
    onSubmit: async (values) => {
      if (!REACT_APP_FORMSPREE_ENDPOINT) {
        console.error('REACT_APP_FORMSPREE_ENDPOINT is undefined')
      } else {
        setLoadingBtn(true)
        await customAxios
          .post(REACT_APP_FORMSPREE_ENDPOINT, {
            Email: values.email,
            Name: values.name,
            CompanyName: values.companyName,
            Phone: values.phone,
            Messenger: values.messenger,
          })
          .then((response) => {
            console.log(response.data)
            setLoadingBtn(false)
            // setResultContact(true)
            formik.resetForm()
          })
          .catch((error) => {
            setLoadingBtn(false)
            console.error(error)
          })
      }
    },
  })

  return (
    <section className="contact" id="contact">
      <div className="main-container custom-container">
        <div className="main-image">
          <img src="/img/hand.webp" alt="hand-sub" />
        </div>
        <div className="wrapper-contact">
          <h2 className="title">{t('Contact')}</h2>
          <h3 className="sub-title">{t('contact_subtitle')}</h3>
          <p className="description">{t('contact_description')}</p>

          <div className="contact-form-main">
            <form onSubmit={formik.handleSubmit}>
              <div className="field-group">
                <div className="ip-part">
                  <Input
                    placeholder={t('Name')}
                    name="name"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <ErrorValidateText>{formik.errors.name}</ErrorValidateText>
                  ) : null}
                </div>
                <div className="ip-part">
                  <Input
                    placeholder={t('Email Address')}
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <ErrorValidateText>{formik.errors.email}</ErrorValidateText>
                  ) : null}
                </div>
              </div>
              <div className="field-group">
                <div className="ip-part">
                  <Input
                    placeholder={t('Company Name')}
                    name="companyName"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.companyName}
                  />
                  {formik.touched.companyName && formik.errors.companyName ? (
                    <ErrorValidateText>
                      {formik.errors.companyName}
                    </ErrorValidateText>
                  ) : null}
                </div>
                <div className="ip-part">
                  <Input
                    placeholder={t('Phone Number')}
                    name="phone"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <ErrorValidateText>{formik.errors.phone}</ErrorValidateText>
                  ) : null}
                </div>
              </div>
              <div className="ip-full">
                <TextArea
                  placeholder={t('Messenger...')}
                  name="messenger"
                  onChange={formik.handleChange}
                  value={formik.values.messenger}
                  rows={5}
                ></TextArea>
                {formik.touched.messenger && formik.errors.messenger ? (
                  <ErrorValidateText>
                    {formik.errors.messenger}
                  </ErrorValidateText>
                ) : null}
              </div>
              <div className="box-actions">
                <Button
                  className={classNames(
                    'btn-loading mt-[26px] w-240px h-[60px]',
                    { load: loadingBtn }
                  )}
                  disabled={loadingBtn}
                  // onClick={() => setResultContact(false)}
                >
                  <>
                    <span className="span-text">{t('Send')}</span>
                    <span className="loading-spinner"></span>
                  </>
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
