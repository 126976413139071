import './style.scss'

import { Pagination, PaginationProps } from 'antd'
import React from 'react'

interface CustomPaginationProps extends PaginationProps {
  totalItems: number;
}

const CustomPagination: React.FC<CustomPaginationProps> = ({
  totalItems,
  ...props
}) => {
  return (
    <div className="custom-pagination">
      <Pagination total={totalItems} {...props} />
    </div>
  )
}

export default CustomPagination
