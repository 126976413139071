import './style.scss'

import { useTranslation } from 'react-i18next'

import Button from '@/components/Common/Item/Button'
import UploadDragger from '@/components/Common/Item/UploadDragger'
import useScrollToSection from '@/components/CustomHook/useScrollToSection'

const Banner: React.FC = () => {
  const { t } = useTranslation(['common'])
  const { scrollToSection } = useScrollToSection()

  return (
    <section id="banner">
      <div className="banner-ocr">
        <div className="content-container">
          <div className="content">
            <p className="title">{t('OCR Scanner')}</p>
            <div className="child-content">
              <p>{t('ocr_description')}</p>
            </div>
            <Button
              className="explore_more min-w-[245px] h-[48px]"
              onClick={() => scrollToSection('demo-ocr')}
            >
              {t('Upload file')}
            </Button>
          </div>
          <div className="bg-ai_ocr bg-contain bg-no-repeat w-[526px] h-[406px]"></div>
        </div>

        <div className="main-container custom-container" id="demo-ocr">
          <h1 className="text-[46px] text-center font-bold">
            {t('Optical Character Recognition (OCR)')}
          </h1>
          <p className="text-center leading-6 mt-[18px] mb-[90px]">
            {t('Convert Image to Text (Extract Text from Image)')}
          </p>

          <div className="scanner-body">
            <div className="wrapper-input">
              <h3 className="title">{t('Optical Character Recognition')}</h3>
              <p className="description">
                {t(
                  'Extract text from Images, PDFs, and Documents with one click.'
                )}
              </p>

              <div className="box-actions">
                <UploadDragger />
              </div>

              {/* <div className="wrapper-result">
                <div className="result-box"></div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Banner
