import './style.scss'

import { useTranslation } from 'react-i18next'

import ImageBlockchain1 from '@/assets/img/top/blockchain_1.png'
import ImageBlockchain2 from '@/assets/img/top/blockchain_2.png'
import ImageBlockchain3 from '@/assets/img/top/blockchain_3.png'
import ImageBlockchain4 from '@/assets/img/top/blockchain_4.png'
import IconBlockchain1 from '@/assets/img/top/fi_blockchain_1.png'
import IconBlockchain2 from '@/assets/img/top/fi_blockchain_2.png'
import IconBlockchain3 from '@/assets/img/top/fi_blockchain_3.png'
import IconBlockchain4 from '@/assets/img/top/fi_blockchain_4.png'

interface CardProps {
  image: string
  icon: string
  title: string
}

const CardItem: React.FC<CardProps> = ({ image, icon, title }) => {
  return (
    <div className="card-item">
      <img src={image} alt={title} />
      <div className="card-content">
        <img src={icon} alt={title} />
        <p className="title">{title}</p>
      </div>
    </div>
  )
}

const BlockchainSection = () => {
  const { t } = useTranslation(['common'])

  const listCards = [
    {
      image: ImageBlockchain1,
      icon: IconBlockchain1,
      title: t('blockchain_tech_t1'),
    },
    {
      image: ImageBlockchain2,
      icon: IconBlockchain2,
      title: t('blockchain_tech_t2'),
    },
    {
      image: ImageBlockchain3,
      icon: IconBlockchain3,
      title: t('blockchain_tech_t3'),
    },
    {
      image: ImageBlockchain4,
      icon: IconBlockchain4,
      title: t('blockchain_tech_t4'),
    },
  ]

  const renderListCards = listCards
    .splice(1, 3)
    .map((item, index) => (
      <CardItem
        image={item.image}
        icon={item.icon}
        title={item.title}
        key={index}
      />
    ))

  return (
    <section className="blockchain-service" id="blockchain-service">
      <div className="main-container custom-container">
        <div className="title-main-card">
          <div className="content">
            <h2 className="title">{t('Blockchain Technology')}</h2>
            <p className="description">{t('blockchain_tech_description')}</p>
          </div>

          <div className="box-card">
            <CardItem
              image={listCards[0].image}
              icon={listCards[0].icon}
              title={listCards[0].title}
            />
          </div>
        </div>
        <div className="list-card">{renderListCards}</div>
      </div>
    </section>
  )
}

export default BlockchainSection
