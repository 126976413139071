import axios, { AxiosRequestConfig } from 'axios'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 10000,
})

axiosInstance.interceptors.request.use(
  (request) => {
    return request
  },
  (error) => {
    console.log(error)
    throw error
  }
)

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    console.log(error)
    throw error
  }
)

const customAxios = {
  get: <T>(url: string, config?: AxiosRequestConfig<T>) =>
    axiosInstance.get(url, config),
  post: <T>(url: string, data?: T, config?: AxiosRequestConfig<T>) =>
    axiosInstance.post(url, data, config),
  put: <T>(url: string, data?: T, config?: AxiosRequestConfig<T>) =>
    axiosInstance.put(url, data, config),
  delete: <T>(url: string, config?: AxiosRequestConfig<T>) =>
    axiosInstance.delete(url, config),
}

export default customAxios
