import './style.scss'

import Layout from '@/components/Common/Layout/MainLayout/Layout'

import Banner from './Banner'
import Difference from './Difference'
import FAQ from './FAQ'
import FavoriteFeatures from './FavoriteFeatures'
import StepBySteps from './StepBySteps'

const AIOcr: React.FC = () => {
  return (
    <Layout>
      <Banner />
      <StepBySteps />
      <Difference />
      <FavoriteFeatures />
      <FAQ />
    </Layout>
  )
}

export default AIOcr
