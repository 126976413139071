/* eslint-disable react/display-name */
import './style.scss'

import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import IconTicked from '../../../../assets/img/Circle_Check.png'
import IconWhyChooseUs1 from '../../../../assets/img/why-choose-us-1.png'
import IconWhyChooseUs2 from '../../../../assets/img/why-choose-us-2.png'
import IconWhyChooseUs3 from '../../../../assets/img/why-choose-us-3.png'
import IconWhyChooseUs4 from '../../../../assets/img/why-choose-us-4.png'
import SubIconWhyChooseUs1 from '../../../../assets/img/why-choose-us-b1.png'
import SubIconWhyChooseUs2 from '../../../../assets/img/why-choose-us-b2.png'
import SubIconWhyChooseUs3 from '../../../../assets/img/why-choose-us-b3.png'
import SubIconWhyChooseUs4 from '../../../../assets/img/why-choose-us-b4.png'

const AboutUs = () => {
  const { t } = useTranslation(['common'])

  const [currentTab, setCurrentTab] = useState(0)

  const listContents = [
    {
      title: t('about_us_t1'),
      icon: IconWhyChooseUs1,
      subIcon: SubIconWhyChooseUs1,
      description: t('about_us_d1'),
    },
    {
      title: t('about_us_t2'),
      icon: IconWhyChooseUs2,
      subIcon: SubIconWhyChooseUs2,
      description: t('about_us_d2'),
    },
    {
      title: t('about_us_t3'),
      icon: IconWhyChooseUs3,
      subIcon: SubIconWhyChooseUs3,
      description: t('about_us_d3'),
    },
    {
      title: t('about_us_t4'),
      icon: IconWhyChooseUs4,
      subIcon: SubIconWhyChooseUs4,
      description: t('about_us_d4'),
    },
  ]

  const renderTabList = listContents.map((item, index) => (
    <div
      className={classNames('tab-item', { active: index === currentTab })}
      key={index}
      onClick={() => setCurrentTab(index)}
    >
      <img src={item.icon} alt={item.title} />
      <p className="title">{item.title}</p>
    </div>
  ))

  return (
    <section className="about-us" id="about-us">
      <div className="main-container custom-container">
        <h1 className="text-black text-[36px] text-left uppercase font-bold">
          {t('Why BAP?')}
        </h1>
        <p className="text-left text-[#383838] leading-6 mt-[24px] mb-[36px]">
          {t('about_us_des_1')}
        </p>

        <div className="list-contents">
          <div className="tab-list-items">{renderTabList}</div>
          <div className="tab-contents">
            <img src={IconTicked} alt="" width={24} height={24} />
            <span>{listContents[currentTab].description}</span>

            <img
              src={listContents[currentTab].subIcon}
              alt=""
              className="sub-icon"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutUs
