import './style.scss'

import React from 'react'

interface ProductDemoProps {
  image?: string;
  title?: string;
  tag?: string;
  description?: string;
  onClick?: () => void;
}

const ProductDemo: React.FC<ProductDemoProps> = ({
  image,
  title,
  tag,
  description,
  onClick,
}) => {
  return (
    <div className="product-demo-card" onClick={onClick}>
      <div className="card-image">
        <img src={image} alt={title} />
      </div>

      <div className="card-content">
        <p className="card-tag">{tag}</p>
        <p className="card-title">{title}</p>
        {description && <p className="card-description">{description}</p>}
      </div>
    </div>
  )
}

export default ProductDemo
