import './SidebarAIProducts.scss'

import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import IconChat from '@/assets/svg/fi_ai_chat.svg'
import IconFace from '@/assets/svg/fi_ai_face.svg'
import IconImage from '@/assets/svg/fi_ai_image.svg'
import IconOCR from '@/assets/svg/fi_ai_ocr.svg'
import IconText from '@/assets/svg/fi_ai_text.svg'

const SidebarAIProducts: React.FC = () => {
  const { t } = useTranslation(['common'])
  const { pathname } = useLocation()

  const listMenu = [
    {
      name: t('Speech to Text'),
      url: 'speech-to-text',
      icon: IconText,
    },
    {
      name: t('OCR'),
      url: 'ocr',
      icon: IconOCR,
    },
    {
      name: t('Face Recognition'),
      url: 'face-recognition',
      icon: IconFace,
    },
    {
      name: t('Open-Source AI Chats'),
      url: 'ai-chats',
      icon: IconChat,
    },
    {
      name: t('AI Image Generator'),
      url: 'ai-image-generator',
      icon: IconImage,
    },
  ]

  const renderListMenu = listMenu.map((item, index) => {
    let isActive
    const path = pathname.slice(12)

    if (index === 0 && (path === '' || path === '/')) {
      isActive = true
    } else {
      isActive = path.includes(item.url)
    }

    return (
      <Link to={item.url} key={index}>
        <li className={classNames('nav-item', { active: isActive })}>
          <img src={item.icon} alt={item.name} />
          <span className="name">{item.name}</span>
        </li>
      </Link>
    )
  })

  return (
    <div className="sidebar">
      <ul className="nav-list">{renderListMenu}</ul>
    </div>
  )
}

export default SidebarAIProducts
