import './style.scss'

import { useTranslation } from 'react-i18next'

import PageTitle from '@/components/Common/Item/PageTitle'
import Select from '@/components/Common/Item/Select'
import TextArea from '@/components/Common/Item/TextArea'
import UploadImage from '@/components/Common/Item/UploadImage'

const AIImageGenerator: React.FC = () => {
  const { t } = useTranslation(['common'])

  return (
    <div className="ai-image-generator">
      <PageTitle title={t('AI Image Generator')} />

      <div className="page-body">
        <div className="wrapper-form">
          <h3 className="title">{t('Create image')}</h3>
          <TextArea rows={3} className="mb-[8px]" />

          <UploadImage />

          <Select
            options={[
              {
                value: 'value',
                label: 'label',
              },
            ]}
            onOptionChange={(value) => {
              console.log('value: ', value)
            }}
          />
        </div>
        <div className="wrapper-result"></div>
      </div>
    </div>
  )
}

export default AIImageGenerator
