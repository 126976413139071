import './style.scss'

import { LoadingOutlined } from '@ant-design/icons'
import type { GetProp, UploadProps } from 'antd'
import { Upload } from 'antd'
import classNames from 'classnames'
import React, { useState } from 'react'

import AddPlusCircle from '@/assets/svg/Add_Plus_Circle.svg'

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

const getBase64 = (img: FileType, callback: () => void) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

const beforeUpload = (file: FileType) => {
  console.log('file: ', file)
  // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'

  // if (!isJpgOrPng) {
  //   message.error('You can only upload JPG/PNG file!')
  // }

  // const isLt2M = file.size / 1024 / 1024 < 2

  // if (!isLt2M) {
  //   message.error('Image must smaller than 2MB!')
  // }

  return false
}

interface CustomUploadProps extends UploadProps {
  className?: string;
}

const UploadImage: React.FC<CustomUploadProps> = ({ className }) => {
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState('')

  const handleChange: UploadProps['onChange'] = (info) => {
    getBase64(info.file, (url) => {
      setLoading(false)
      setImageUrl(url)
    })
  }

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {loading ? (
        <LoadingOutlined />
      ) : (
        <img src={AddPlusCircle} alt="" width={24} height={24} />
      )}
      <div style={{ marginTop: 8 }}>Upload Image</div>
    </button>
  )

  return (
    <Upload
      listType="picture-card"
      className={classNames('image-uploader', className)}
      showUploadList={false}
      action=""
      beforeUpload={beforeUpload}
      customRequest={() => {
        // Do nothing here, because upload is manual
      }}
      onChange={handleChange}
    >
      {imageUrl ? (
        <img src={imageUrl} alt="" style={{ width: '100%' }} />
      ) : (
        uploadButton
      )}
    </Upload>
  )
}

export default UploadImage
