import './style.scss'

import { useTranslation } from 'react-i18next'

import ImageDigitalTrans1 from '@/assets/img/digital_trans_1.png'
import ImageDigitalTrans2 from '@/assets/img/digital_trans_2.png'
import ImageDigitalTrans3 from '@/assets/img/digital_trans_3.png'
import ImageDigitalTrans4 from '@/assets/img/digital_trans_4.png'
import Button from '@/components/Common/Item/Button'
import ProductDemo from '@/components/Common/Item/ProductDemoCard'

const DigitalTransformation = () => {
  const { t } = useTranslation(['common'])

  const listDigitalTransformation = [
    {
      image: ImageDigitalTrans1,
      tag: 'DX Consulting',
      title: 'Odoo-powered, comprehensive digital transformation solutions',
    },
    {
      image: ImageDigitalTrans2,
      tag: 'DX Consulting',
      title: 'Human Resources Management System: A Revolutionary Approach',
    },
    {
      image: ImageDigitalTrans3,
      tag: 'DX Consulting',
      title: 'Streamlining Manpower Management for SMEs',
    },
    {
      image: ImageDigitalTrans4,
      tag: 'DX Consulting',
      title:
        'Revolutionizing Equipment Management Through Digital Transformation',
    },
  ]

  const listItems = listDigitalTransformation.map((item, index) => {
    return (
      <ProductDemo
        key={index}
        image={item.image}
        tag={item.tag}
        title={item.title}
      />
    )
  })

  return (
    <section className="digital_transformation" id="digital_transformation">
      <div className="main-container custom-container">
        <div className="box-title">
          <h2 className="title">{t('Digital Transformation')}</h2>
        </div>
        <p className="description">{t('digital_transformation_description')}</p>

        <div className="list-card-items">{listItems}</div>

        <div className="box-actions">
          <Button className="read-more w-[160px] h-[48px]">
            {t('See all')}
          </Button>
        </div>
      </div>
    </section>
  )
}

export default DigitalTransformation
