import './style.scss'

import { useTranslation } from 'react-i18next'

import ImageConvertAudio from '@/assets/img/speech2text/convert-audio.png'
import ImageConvertVideo from '@/assets/img/speech2text/convert-video.png'
import Button from '@/components/Common/Item/Button'

const FastAndAccurately = () => {
  const { t } = useTranslation(['common'])

  const listItems = [
    {
      image: ImageConvertVideo,
      title: t('fa_convert_video_title'),
      description: t('fa_convert_video_description'),
    },
    {
      image: ImageConvertAudio,
      title: t('fa_convert_audio_title'),
      description: t('fa_convert_audio_description'),
    },
  ]

  const renderListItems = listItems.map((item, index) => {
    return (
      <div className="wrapper-convert-content" key={index}>
        <div className="convert-content">
          <p className="convert-title">{item.title}</p>
          <p className="convert-description">{item.description}</p>
          <Button className="min-w[245px] h-[48px]">
            {t('Convert audio to text now')}
          </Button>
        </div>

        <div className="convert-image">
          <img src={item.image} alt={item.title} />
        </div>
      </div>
    )
  })

  return (
    <section className="fast-and-accurately" id="fast-and-accurately">
      <div className="main-container custom-container">
        <h2 className="title">
          {t('Convert Speech to Text Online Fast and Accurately')}
        </h2>
        <p className="description">{t('fast_accurately_description')}</p>

        <div className="list-content-items">{renderListItems}</div>
      </div>
    </section>
  )
}

export default FastAndAccurately
