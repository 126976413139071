import './style.scss'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import ImageCaseStudy1 from '@/assets/img/case_study_1.png'
import ImageCaseStudy2 from '@/assets/img/case_study_2.png'
import ImageCaseStudy3 from '@/assets/img/case_study_3.png'
// import ImageCaseStudy4 from '@/assets/img/case_study_4.png'
import Button from '@/components/Common/Item/Button'
import ProductDemo from '@/components/Common/Item/ProductDemoCard'

const CaseStudy = () => {
  const { t } = useTranslation(['common'])
  const navigate = useNavigate()

  const listCaseStudy = [
    {
      image: ImageCaseStudy1,
      tag: 'AI',
      title: 'AI-EDU – Automated Classroom Question Creation',
    },
    {
      image: ImageCaseStudy2,
      tag: 'AI',
      title: 'Power Consumption AI Prediction: Smart Energy Management',
    },
    {
      image: ImageCaseStudy3,
      tag: 'AI',
      title:
        'Virtual Power Plant System: An innovative method of energy management',
    },
    // {
    //   image: ImageCaseStudy4,
    //   tag: 'AI',
    //   title: 'Wines Detection',
    // },
  ]

  const listItems = listCaseStudy.map((item, index) => {
    return (
      <ProductDemo
        key={index}
        image={item.image}
        tag={item.tag}
        title={item.title}
        onClick={() => navigate('/case-study/1')}
      />
    )
  })

  return (
    <section className="case_study" id="case_study">
      <div className="main-container custom-container">
        <div className="box-title">
          <h2 className="title">{t('Case Study')}</h2>
        </div>
        <p className="description">{t('case_study_description')}</p>

        <div className="list-card-items">{listItems}</div>

        <div className="box-actions">
          <Button
            className="read-more w-[160px] h-[48px]"
            onClick={() => navigate('case-study')}
          >
            {t('See all')}
          </Button>
        </div>
      </div>
    </section>
  )
}

export default CaseStudy
