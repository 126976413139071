import './style.scss'

import { useTranslation } from 'react-i18next'

import PageTitle from '@/components/Common/Item/PageTitle'
import UploadDragger from '@/components/Common/Item/UploadDragger'

const OCR: React.FC = () => {
  const { t } = useTranslation(['common'])

  return (
    <div className="ocr">
      <PageTitle title={t('Optical Character Recognition (OCR)')} />

      <div className="page-body">
        <div className="wrapper-input">
          <h3 className="title">{t('Image to Text')}</h3>
          <p className="description">
            {t(`Convert Image to Text (Extract Text from Image)`)}
          </p>

          <div className="box-actions">
            <UploadDragger />
          </div>
        </div>
        <div className="wrapper-result">
          <p className="result-label">{t('Result')}</p>
          <div className="result-box"></div>
        </div>
      </div>
    </div>
  )
}

export default OCR
