import './style.scss'

import { Input } from 'antd'
import { TextAreaProps } from 'antd/lib/input'
import classNames from 'classnames'
import React from 'react'

const { TextArea } = Input

interface CustomTextAreaProps extends TextAreaProps {
  label?: string;
  className?: string;
}

const CustomTextArea: React.FC<CustomTextAreaProps> = ({
  label,
  className,
  ...props
}) => {
  return (
    <div className={classNames('default-text-area', className)}>
      {label && <label>{label}</label>}
      <TextArea {...props} />
    </div>
  )
}

export default CustomTextArea
