import './style.scss'

import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import ImageCaseStudy1 from '@/assets/img/case_study_1.png'
import ImageCaseStudy2 from '@/assets/img/case_study_2.png'
import ImageCaseStudy3 from '@/assets/img/case_study_3.png'
import Search from '@/assets/svg/search.svg'
import Button from '@/components/Common/Item/Button'
import Input from '@/components/Common/Item/Input'
import Pagination from '@/components/Common/Item/Pagination'
import ProductDemo from '@/components/Common/Item/ProductDemoCard'
import Layout from '@/components/Common/Layout/MainLayout/Layout'

import PageTitle from '../../Item/PageTitleBg'

const CaseStudyList: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [tagActive, setTagActive] = useState<string | null>('1')

  const listTags = [
    {
      id: '1',
      name: 'All Categories',
      count: '100',
    },
    {
      id: '2',
      name: 'AI Products',
      count: '25',
    },
    {
      id: '3',
      name: 'Digital Transformation',
      count: '25',
    },
    {
      id: '4',
      name: 'Blockchain',
      count: '25',
    },
    {
      id: '5',
      name: 'Game & Metaverse',
      count: '25',
    },
  ]

  const listCaseStudy = [
    {
      image: ImageCaseStudy1,
      tag: 'AI',
      title: 'AI-EDU – Automated Classroom Question Creation',
      description:
        'Visitors can interact with multiple open-source Large Language Models for dynamic and flexible conversations',
    },
    {
      image: ImageCaseStudy2,
      tag: 'AI',
      title: 'Power Consumption AI Prediction: Smart Energy Management',
      description:
        'In recent years, economic development and the pursuit of comfort have significantly increased energy demand. The importance of optimizing energy systems is apparent in light of the limited energy resources and the urgency to on serve energy. As a result, the market for smart grids and homes in modern smart cities is growing rapidly.  ',
    },
    {
      image: ImageCaseStudy3,
      tag: 'AI',
      title:
        'Virtual Power Plant System: An innovative method of energy management',
      description:
        'A number of technological advancements as well as climate changes are affecting the energy industry worldwide. In addition to traditional centralized power plants, distributed energy resources (DERs), such as solar energy and energy storage systems, are now being deployed to complement them.',
    },
    {
      image: ImageCaseStudy1,
      tag: 'AI',
      title: 'AI-EDU – Automated Classroom Question Creation',
      description:
        'Visitors can interact with multiple open-source Large Language Models for dynamic and flexible conversations',
    },
    {
      image: ImageCaseStudy2,
      tag: 'AI',
      title: 'Power Consumption AI Prediction: Smart Energy Management',
      description:
        'In recent years, economic development and the pursuit of comfort have significantly increased energy demand. The importance of optimizing energy systems is apparent in light of the limited energy resources and the urgency to on serve energy. As a result, the market for smart grids and homes in modern smart cities is growing rapidly.  ',
    },
    {
      image: ImageCaseStudy3,
      tag: 'AI',
      title:
        'Virtual Power Plant System: An innovative method of energy management',
      description:
        'A number of technological advancements as well as climate changes are affecting the energy industry worldwide. In addition to traditional centralized power plants, distributed energy resources (DERs), such as solar energy and energy storage systems, are now being deployed to complement them.',
    },
  ]

  const listItems = listCaseStudy.map((item, index) => {
    return (
      <ProductDemo
        key={index}
        image={item.image}
        tag={item.tag}
        title={item.title}
        description={item.description}
        onClick={() => navigate('/case-study/1')}
      />
    )
  })

  const renderListTags = listTags.map((item, index) => {
    const isActive = tagActive === item.id
    return (
      <div
        className={classNames('tag-item', { active: isActive })}
        key={index}
        onClick={() => setTagActive(item.id)}
      >{`${item.name} (${item.count})`}</div>
    )
  })

  return (
    <Layout>
      <PageTitle title={t('Case Study')} />

      <div className="case-study-list">
        <div className="main-container custom-container">
          <div className="filter">
            <div className="box-search">
              <Input
                className="input-search"
                placeholder="Search here..."
                prefix={<img src={Search} width={24} height={24} />}
              />
              <Button className="btn-search min-w-[165px] h-[48px]">
                {t('Search')}
              </Button>
            </div>
            <div className="list-tags">{renderListTags}</div>
          </div>

          <div className="list-card-items">{listItems}</div>

          <div className="box-pagination">
            <Pagination current={1} pageSize={6} totalItems={10} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CaseStudyList
