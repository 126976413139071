import './style.scss'

import { useTranslation } from 'react-i18next'

import ImageFeatures1 from '@/assets/img/difyAI/features.png'
import ImageFeatures2 from '@/assets/img/difyAI/features_2.png'

const FavoriteFeatures = () => {
  const { t } = useTranslation(['common'])

  const listFeatures = [
    {
      title: t('dify_ai_feature_title_1'),
      description: t('dify_ai_feature_description_1'),
    },
    {
      title: t('dify_ai_feature_title_2'),
      description: t('dify_ai_feature_description_2'),
    },
    {
      title: t('dify_ai_feature_title_3'),
      description: t('dify_ai_feature_description_3'),
    },
    {
      title: t('dify_ai_feature_title_4'),
      description: t('dify_ai_feature_description_4'),
    },
    {
      title: t('dify_ai_feature_title_5'),
      description: t('dify_ai_feature_description_5'),
    },
  ]

  const renderListFeatures = listFeatures.map((item, index) => (
    <div className="feature-content-item" key={index}>
      <h3 className="feature-title">{item.title}</h3>
      <p className="feature-description">{item.description}</p>
    </div>
  ))

  return (
    <section className="dify-ai-features" id="dify-ai-features">
      <div className="main-container custom-container">
        <h2 className="title">{t('Features')}</h2>

        <div className="image-top">
          <img src={ImageFeatures1} alt="Dify AI demo" />
        </div>

        <div className="wrapper-contents">
          <div className="contents">{renderListFeatures}</div>
          <div className="image-content">
            <img src={ImageFeatures2} alt="Dify AI demo" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default FavoriteFeatures
