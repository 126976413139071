import './style.scss'

import { useTranslation } from 'react-i18next'

import ImageFeature1 from '@/assets/img/ocr/feature_1.png'
import ImageFeature2 from '@/assets/img/ocr/feature_2.png'
import ImageFeature3 from '@/assets/img/ocr/feature_3.png'

const FavoriteFeatures = () => {
  const { t } = useTranslation(['common'])

  const listItems = [
    {
      image: ImageFeature1,
      title: t('ocr_features_title_1'),
      description: t('ocr_features_description_1'),
    },
    {
      image: ImageFeature2,
      title: t('ocr_features_title_2'),
      description: t('ocr_features_description_2'),
    },
    {
      image: ImageFeature3,
      title: t('ocr_features_title_3'),
      description: t('ocr_features_description_3'),
    },
  ]

  const renderListItems = listItems.map((item, index) => {
    return (
      <div className="wrapper-favorite-features-content" key={index}>
        <div className="favorite-features-content">
          <p className="favorite-features-title">{item.title}</p>
          <p className="favorite-features-description">{item.description}</p>
        </div>

        <div className="favorite-features-image">
          <img src={item.image} alt={item.title} />
        </div>
      </div>
    )
  })

  return (
    <section className="favorite-features" id="favorite-features">
      <div className="main-container custom-container">
        <h2 className="title">
          {t('Cutting-Edge Technology Enhances Document Automation')}
        </h2>

        <div className="list-content-items">{renderListItems}</div>
      </div>
    </section>
  )
}

export default FavoriteFeatures
