import './style.scss'

import { useTranslation } from 'react-i18next'

import IconAIDify from '@/assets/svg/ai_dify.svg'
import IconAIFace from '@/assets/svg/ai_face.svg'
import IconAILIama3 from '@/assets/svg/ai_LIama3.svg'
import IconAILibreChat from '@/assets/svg/ai_libreChat.svg'
import IconAIOCR from '@/assets/svg/ai_ocr.svg'
import IconAIStableDiffusion from '@/assets/svg/ai_stableDiffusion.svg'
import IconAIText from '@/assets/svg/ai_text.svg'

const AISection = () => {
  const { t } = useTranslation(['common'])

  const listAI = [
    {
      icon: IconAIText,
      title: t('Speech to Text'),
    },
    {
      icon: IconAIOCR,
      title: t('OCR'),
    },
    {
      icon: IconAIFace,
      title: t('Face Recognition'),
    },
    {
      icon: IconAIDify,
      title: t('Dify.ai'),
    },
    {
      icon: IconAILibreChat,
      title: t('LibreChat'),
    },
    {
      icon: IconAILIama3,
      title: t('Llama3'),
    },
    {
      icon: IconAIStableDiffusion,
      title: t('Stable Diffusion'),
    },
  ]

  const renderListAI = listAI.map((item, index) => (
    <div className="ai-item" key={index}>
      <div className="icon">
        <img src={item.icon} alt={item.title} />
      </div>

      <p className="title">{item.title}</p>
    </div>
  ))

  return (
    <section className="ai-service" id="ai-service">
      <div className="main-container custom-container">
        <div className="title-description">
          <h2 className="title">{t('Artificial Intelligence')}</h2>
          <p className="description">{t('AI_description')}</p>
        </div>

        <div className="list-ai">{renderListAI}</div>
      </div>
      <div className="main-container"></div>
    </section>
  )
}

export default AISection
