import './style.scss'

import { Progress } from 'antd'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import ImageStep1 from '@/assets/img/speech2text/img_step_1.png'
import ImageStep2 from '@/assets/img/speech2text/img_step_2.png'
import ImageStep3 from '@/assets/img/speech2text/img_step_3.png'
import IconStep1 from '@/assets/img/speech2text/step_1.svg'
import IconStep2 from '@/assets/img/speech2text/step_2.svg'
import IconStep3 from '@/assets/img/speech2text/step_3.svg'
import useTimeoutTabs from '@/hooks/useTimeoutTabs'

const StepBySteps = () => {
  const { t } = useTranslation(['common'])

  const { currentStep, setCurrentStep, currentCount, setCurrentCount } =
    useTimeoutTabs()

  const listTabs = [
    {
      id: 1,
      icon: IconStep1,
      image: ImageStep1,
      title: t('speech2text_step_title_1'),
      description: t('speech2text_step_description_1'),
    },
    {
      id: 2,
      icon: IconStep2,
      image: ImageStep2,
      title: t('speech2text_step_title_2'),
      description: t('speech2text_step_description_2'),
    },
    {
      id: 3,
      icon: IconStep3,
      image: ImageStep3,
      title: t('speech2text_step_title_3'),
      description: t('speech2text_step_description_3'),
    },
  ]

  const renderListTabs = listTabs.map((item, index) => {
    const isActive = currentStep === item.id

    const percent = currentStep > item.id ? 100 : isActive ? currentCount : 0
    return (
      <div
        className={classNames('tab-item', { active: isActive })}
        key={index}
        onClick={() => {
          setCurrentCount(0)
          setCurrentStep(item.id)
        }}
      >
        <div className="tab-icon">
          <img src={item.icon} alt={item.title} />
        </div>

        <div className="tab-content">
          <p className="tab-title">{item.title}</p>
          <p className="tab-description">{item.description}</p>
          <Progress
            className="step-progress"
            percent={percent}
            showInfo={false}
          />
        </div>
      </div>
    )
  })

  return (
    <section className="step-by-steps" id="step-by-steps">
      <div className="main-container custom-container">
        <h2 className="title">
          {t('How to convert speech to text automatically?')}
        </h2>

        <div className="wrapper-steps">
          <div className="list-steps">{renderListTabs}</div>
          <div className="current-step-content">
            <div className="image-step">
              <img
                src={listTabs[currentStep - 1].image}
                alt={listTabs[currentStep - 1].title}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default StepBySteps
