import './style.scss'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ImageMic from '@/assets/img/mic.png'
import ImageMicPause from '@/assets/img/mic_pause.png'
import ImageSound from '@/assets/img/sound.png'
import IconUploadFile from '@/assets/svg/upload-file.svg'
import Button from '@/components/Common/Item/Button'
import useScrollToSection from '@/components/CustomHook/useScrollToSection'

const Banner: React.FC = () => {
  const { t } = useTranslation(['common'])
  const { scrollToSection } = useScrollToSection()
  const [toggleMic, setToggleMic] = useState(false)

  return (
    <section id="banner">
      <div className="banner-speech2text">
        <div className="content-container">
          <div className="content">
            <p className="title">{t('Speech to Text')}</p>
            <div className="child-content">
              <p>{t('speech2text_description')}</p>
            </div>
            <Button
              className="explore_more min-w[245px] h-[48px]"
              onClick={() => scrollToSection('demo-speech2text')}
            >
              {t('Convert audio to text now')}
            </Button>
          </div>
          <div className="bg-ai_speech2text bg-contain bg-no-repeat w-[526px] h-[406px]"></div>
        </div>

        <div className="main-container custom-container" id="demo-speech2text">
          <h1 className="text-[46px] text-center font-bold">
            {t('Speech to text platform')}
          </h1>
          <p className="text-center leading-6 mt-[18px] mb-[90px]">
            {t('To get started, select your language.')}
          </p>

          <div className="record-body">
            <div className="wrapper-input">
              <h3 className="title">{t('Record from browser')}</h3>
              <p className="description">
                {t('Click the microphone icon and speak')}
              </p>

              <div
                className="record-images"
                onClick={() => setToggleMic(!toggleMic)}
              >
                <img src={ImageSound} className="img-sound" alt="sound" />
                <img
                  src={toggleMic ? ImageMic : ImageMicPause}
                  className="img-mic"
                  alt="mic"
                />
              </div>

              <p className="time-record">00:00:00</p>

              <div className="box-actions">
                <hr />
                <p className="note-upload">
                  {t('Or upload an existing audio file')}
                </p>

                <Button radius className="w-[100%] h-[40px] btn-upload">
                  <img
                    src={IconUploadFile}
                    alt="upload file"
                    width={24}
                    height={24}
                  />
                  <span>{t('Upload your file')}</span>
                </Button>
              </div>
            </div>
            <div className="wrapper-result">
              <div className="result-box"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Banner
