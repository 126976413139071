import './style.scss'

import Layout from '@/components/Common/Layout/MainLayout/Layout'
import Bannervideo from '@/components/Common/Pages/BannerVideo'

import AISection from './AISection'
import Awards from './Awards'
import BlockchainSection from './BlockchainSection'
import CaseStudy from './CaseStudy'
import Clients from './Clients'
import DigitalTransformation from './DigitalTransformation'
import MVPBuilding from './MVPBuilding'
// import OurPartner from '@/components/Common/Pages/Top/OurPartner/indexV2'
// import ERPSection from './ERPSection'

const Blog: React.FC = () => {
  return (
    <Layout>
      <Bannervideo />
      <AISection />
      <CaseStudy />
      <DigitalTransformation />
      <BlockchainSection />
      <MVPBuilding />
      <Awards />
      <Clients />
      {/* <ERPSection /> */}
      {/* <OurPartner /> */}
    </Layout>
  )
}

export default Blog
