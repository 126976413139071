// eslint-disable-next-line simple-import-sort/imports
import { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import SubLayout from '@/components/Common/Layout/MainLayout/SubLayout'

// import { MainLayout } from '@/components/Common/Layout'
import Blog from '@/components/Common/Pages/Blog'
import Top from '@/components/Common/Pages/Top'
import BlogDetail from '@/components/Common/Pages/BlogDetail'
import ProjectDetail from '@/components/Common/Pages/ProjectDetail'
import WhisperV3 from '@/components/Common/Pages/ProjectDetail/WhisperV3/WhisperV3'
import OCRProductDetail from '@/components/Common/Pages/ProjectDetail/OCR'
import BKYC from '@/components/Common/Pages/ProjectDetail/BKYC'
import BEMO from '@/components/Common/Pages/ProjectDetail/Bemo'

import AISpeech2Text from '@/components/Common/Pages/AISpeech2Text'
import AIOcr from '@/components/Common/Pages/AIOcr'

import DifyAI from '@/components/Common/Pages/DifyAI'
import LibreChat from '@/components/Common/Pages/LibreChat'

import CaseStudyList from '@/components/Common/Pages/CaseStudyList'
import CaseStudyDetail from '@/components/Common/Pages/CaseStudyDetail'

import SpeechToText from '@/components/Common/Pages/AIProducts/SpeechToText'

import OCR from '@/components/Common/Pages/AIProducts/OCR'
import FaceRecognition from '@/components/Common/Pages/AIProducts/FaceRecognition'
import AIChats from '@/components/Common/Pages/AIProducts/AIChats'
import AIImageGenerator from '@/components/Common/Pages/AIProducts/AIImageGenerator'

function PublicRoute() {
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route element={<Top />} path="/" />
        {/* <Route element={<Top />} path="/about-us" /> */}
        <Route element={<Top />} path="/contact" />

        <Route element={<Blog />} path="/blog" />
        <Route element={<BlogDetail />} path="/blog/:blogSlug" />
        <Route element={<WhisperV3 />} path="/bap-whisper-large-v3" />
        <Route element={<OCRProductDetail />} path="/bap-ocr" />
        <Route element={<BKYC />} path="/BKYC" />
        <Route element={<BEMO />} path="/bemo-cloud" />
        <Route element={<ProjectDetail />} path="/product/:slug" />

        <Route path="speech-to-text" element={<AISpeech2Text />} />
        <Route path="ocr" element={<AIOcr />} />

        <Route path="dify-ai" element={<DifyAI />} />
        <Route path="libre-chat" element={<LibreChat />} />

        <Route path="case-study" element={<CaseStudyList />} />
        <Route path="case-study/:id" element={<CaseStudyDetail />} />

        <Route path="/ai-products" element={<SubLayout />}>
          <Route index element={<SpeechToText />} />
          <Route path="speech-to-text-old" element={<SpeechToText />} />
          <Route path="ocr" element={<OCR />} />
          <Route path="face-recognition" element={<FaceRecognition />} />
          <Route path="ai-chats" element={<AIChats />} />
          <Route path="ai-image-generator" element={<AIImageGenerator />} />
        </Route>

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Suspense>
  )
}

export default PublicRoute
