import './style.scss'

import { useTranslation } from 'react-i18next'

import ImageBanner from '@/assets/img/libreChat/banner.png'
import Button from '@/components/Common/Item/Button'

const Banner: React.FC = () => {
  const { t } = useTranslation(['common'])

  return (
    <section id="banner">
      <div className="banner-libre-chat">
        <div className="main-container top">
          <p className="title">{t('LibreChat')}</p>
          <div className="child-content">
            <p>{t('libre_chat_description')}</p>
          </div>

          <div className="btn-action">
            <Button className="try-demo min-w-[245px] h-[48px]">
              {t('Try Demo')}
            </Button>
          </div>
        </div>

        <div className="main-container custom-container img-main">
          <img src={ImageBanner} alt="LibreChat demo" />
        </div>
      </div>
    </section>
  )
}

export default Banner
