import './style.scss'

import { Input, InputProps } from 'antd'
import classNames from 'classnames'
import React from 'react'

interface CustomInputProps extends InputProps {
  label?: string;
  className?: string;
}

const CustomInput: React.FC<CustomInputProps> = ({
  className,
  label,
  ...props
}) => {
  return (
    <div className={classNames('default-input', className)}>
      {label && <label>{label}</label>}
      <Input {...props} />
    </div>
  )
}

export default CustomInput
