import './style.scss'

import { useTranslation } from 'react-i18next'

import ImageFavoriteFeatures1 from '@/assets/img/speech2text/favorite_features_1.png'
import ImageFavoriteFeatures2 from '@/assets/img/speech2text/favorite_features_2.png'
import ImageFavoriteFeatures3 from '@/assets/img/speech2text/favorite_features_3.png'
import ImageFavoriteFeatures4 from '@/assets/img/speech2text/favorite_features_4.png'

const FavoriteFeatures = () => {
  const { t } = useTranslation(['common'])

  const listItems = [
    {
      image: ImageFavoriteFeatures1,
      title: t('favorite_features_title_1'),
      description: t('favorite_features_description_1'),
    },
    {
      image: ImageFavoriteFeatures2,
      title: t('favorite_features_title_2'),
      description: t('favorite_features_description_2'),
    },
    {
      image: ImageFavoriteFeatures3,
      title: t('favorite_features_title_3'),
      description: t('favorite_features_description_3'),
    },
    {
      image: ImageFavoriteFeatures4,
      title: t('favorite_features_title_4'),
      description: t('favorite_features_description_4'),
    },
  ]

  const renderListItems = listItems.map((item, index) => {
    return (
      <div className="wrapper-favorite-features-content" key={index}>
        <div className="favorite-features-content">
          <p className="favorite-features-title">{item.title}</p>
          <p className="favorite-features-description">{item.description}</p>
        </div>

        <div className="favorite-features-image">
          <img src={item.image} alt={item.title} />
        </div>
      </div>
    )
  })

  return (
    <section className="speech2text-features" id="speech2text-features">
      <div className="main-container custom-container">
        <h2 className="title">{t('Favorite Features for')}</h2>

        <div className="list-content-items">{renderListItems}</div>
      </div>
    </section>
  )
}

export default FavoriteFeatures
