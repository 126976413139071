import './style.scss'

import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import React, { useState } from 'react'

const { Option } = Select

interface OptionType {
  value: string;
  label: string;
}

interface CustomSelectProps extends SelectProps {
  options: OptionType[];
  onOptionChange: (value: string) => void;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  onOptionChange,
  ...restProps
}) => {
  const [selectedValue, setSelectedValue] = useState(undefined)

  const handleChange = (value: string) => {
    setSelectedValue(value)
    onOptionChange(value) // Gọi hàm callback để truyền giá trị ra ngoài
  }

  return (
    <Select
      value={selectedValue}
      onChange={handleChange}
      placeholder="Please select"
      style={{ width: 200 }}
      {...restProps} // Thêm các props tùy chỉnh (nếu có)
    >
      {options.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  )
}

export default CustomSelect
