import './style.scss'

import Layout from '@/components/Common/Layout/MainLayout/Layout'

import Banner from './Banner'
import FAQ from './FAQ'
import Features from './Features'

const LibreChat: React.FC = () => {
  return (
    <Layout>
      <Banner />
      <Features />
      <FAQ />
    </Layout>
  )
}

export default LibreChat
