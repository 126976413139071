import './style.scss'

import { useTranslation } from 'react-i18next'

import ImageMetaVerse from '@/assets/img/metaverse.png'
import ImageMVPBuilding from '@/assets/img/mvp_building.png'
import Button from '@/components/Common/Item/Button'
import SlickSlider from '@/components/Common/Item/Slider'

const MVPBuilding = () => {
  const { t } = useTranslation(['common'])

  return (
    <section className="mvp_building" id="mvp_building">
      <div className="main-container custom-container">
        <SlickSlider
          isActions
          settings={{
            dots: true,
            infinite: true,
            speed: 500,
            autoplay: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ],
          }}
        >
          <div className="card-slide-item">
            <div className="project-item">
              <div>
                <div className="building-image">
                  <img src={ImageMetaVerse} alt="Metaverse" />
                </div>
                <div className="building-content">
                  <h2 className="title">{t('The Metaverse Era')}</h2>
                  <p className="description">{t('metaverse_description')}</p>
                  <div className="box-actions">
                    <Button className="read-more w-[160px] h-[48px]">
                      {t('Read more')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-slide-item">
            <div className="project-item">
              <div>
                <div className="building-image">
                  <img src={ImageMVPBuilding} alt="mvp building" />
                </div>
                <div className="building-content">
                  <h2 className="title">{t('MVP Building')}</h2>
                  <p className="description">{t('mvp_building_description')}</p>
                  <div className="box-actions">
                    <Button className="read-more w-[160px] h-[48px]">
                      {t('Read more')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SlickSlider>
      </div>
    </section>
  )
}

export default MVPBuilding
